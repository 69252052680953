interface New {
  title: string
  content: string
}

export const news: New[] = [
  {
    title: 'Bônus de boas vindas',
    content: 'Deposite R$100,00 e ganhe apostas grátis!'
  },
  {
    title: 'Cashback',
    content:
      'Receba dinheiro de volta em todas as suas apostas, não importa se você ganha ou perde!'
  },
  {
    title: 'Promoções',
    content: 'Você sempre encontrará ofertas novas e empolgantes na MiPix!'
  }
]
