<script setup lang="ts">
import type { XiorError } from 'xior';
import type Lobby from '~/interfaces/lobby';
import { emitter } from '~/events'
import { useBreakpoints } from '@vueuse/core'
import { initFlowbite } from 'flowbite'

import { news } from '~/fakedb/news'

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue'

// import Swiper and modules styles
import 'swiper/css';

import './style.css'
import type Question from '~/interfaces/question';

const $http = useHttp()
const router = useRouter()
const appStore = useAppStore()
const authStore = useAuthStore()
const mounted = useMounted()

const questionList = useState<Question[]>('question-list', () => shallowRef([]))
const laptop = ref<boolean>(true)
const route = useRoute()

const breakpoints = useBreakpoints(appStore.breakpoints)
const openSearchModal = () => emitter.emit('search-modal', 'open')

const fetchData = async () => {
  const { data: questions } = await $http.get('/casino/questions')
  questionList.value = questions
}

onServerPrefetch(async () => await fetchData())

onBeforeMount(async () => {
  if (Object.keys(route.query).length > 3 && route.name === 'index') {
    router.replace({ name: 'auth-login' })
    try {
      await authStore.login('oauth2', '', '')
      router.replace({ name: 'index' })
    } catch (err: any | XiorError) {
      if (
        err.response &&
        err.response.data &&
        err.response.data.code == 'not_found'
      ) {
        const { data } = err.response
        const providerData: any = data.provider_user
        providerData.provider = data.provider
        providerData.provider_token = data.provider_token

        authStore.setOauth(providerData)
        authStore.changeType('auth-signup')
      } else {
        router.replace({ name: 'auth-login' })
      }
      appStore.setLoading(false)
    }
  }

  if (!questionList.value.length) {
    await fetchData()
  }
})

onMounted(async () => {
  laptop.value = breakpoints.sm.value

  initFlowbite()
})

watch(breakpoints.md, (newBreakpoint) => laptop.value = newBreakpoint)

const firstLobby = computed<Lobby | null>(() => appStore.lobbyItems.length > 0 ? appStore.lobbyItems[0] : null)
</script>
<template>
  <div class="w-full">
    <div class="w-full flex flex-col justify-start items-start gap-4">
      <div class="search-header">
        <div class="input-container">
          <input type="text" readonly
            class="new-input hover:border-primary-300 focus:border-[#237B5E] focus:ring-transparent cursor-pointer"
            placeholder="Procurar jogos &amp; dealers" data-modal-target="search-modal"
            @click.capture="openSearchModal" />
        </div>
      </div>

      <div class="w-full -mt-4">
        <nuxt-link :to="{ name: 'promotions-promotion', params: { promotion: 'bonus-de-deposito' } }">
          <img v-if="mounted && laptop" src="https://cdn.mipix.bet/uploads/banners/bonus-1d.png"
            class="xl:h-full rounded-2xl hover:opacity-80" />
          <img v-else src="https://cdn.mipix.bet/uploads/banners/bonus-1m.png"
            class="xl:h-full rounded-2xl hover:opacity-80" />
        </nuxt-link>
      </div>

      <div class="w-full" v-if="firstLobby">
        <div class="head-one-category">
          <div class="section-title">
            <span>{{ firstLobby.title }}</span>
          </div>
          <nuxt-link :to="{ name: 'casino-lobby-slug', params: { slug: firstLobby.slug } }" class="category-button">
            <span>{{ firstLobby.items.total }}</span> <span>></span>
          </nuxt-link>
        </div>
        <div class="feature-cards lobby-game">
          <div v-for="(game, key) in firstLobby.items.data.slice(0, laptop ? undefined : 8)" v-bind:key="key"
            :class="{ 'feature-card': !mounted }">
            <GameInfoItem :lobby="firstLobby" :game="game" />
          </div>
        </div>
      </div>

      <div class="w-full mt-2">
        <nuxt-link :to="{ name: 'promotions-promotion', params: { promotion: 'cashback-semanal' } }">
          <img v-if="mounted && laptop" src="https://cdn.mipix.bet/uploads/banners/cash-d.png"
            class="xl:h-full rounded-2xl hover:opacity-80" />
          <img v-else src="https://cdn.mipix.bet/uploads/banners/cash-m.png"
            class="xl:h-full rounded-2xl hover:opacity-80" />
        </nuxt-link>
      </div>

      <div class="w-full" v-for="(item) in appStore.lobbyItems.slice(1)" :key="item.id">
        <div class="head-one-category">
          <div class="section-title">
            <span>{{ item.title }}</span>
          </div>
          <nuxt-link v-if="item.lobby_type === 1" :to="{ name: 'casino-lobby-slug', params: { slug: item.slug } }"
            class="category-button">
            <span>{{ item.items.total }} ></span>
          </nuxt-link>
          <nuxt-link v-else-if="item.lobby_type === 2" :to="{ name: 'casino-provider' }" class="category-button">
            <span>{{ item.providers?.length }} ></span>
          </nuxt-link>
        </div>
        
        <div v-if="item.lobby_type === 1" class="feature-cards lobby-game">
          <div v-for="(game, key) in item.items.data.slice(0, laptop ? undefined : 4)" v-bind:key="key"
            :class="{ 'feature-card': !mounted }">
            <GameInfoItem :lobby="item" :game="game" />
          </div>
        </div>
        <swiper v-else-if="item.lobby_type === 2" class="feature-cards" slidesPerView="auto">
          <swiper-slide v-for="provider in item.providers.slice(0, 20)" class="feature-card first:ml-0 ml-2 !w-52 h-2">
            <nuxt-link :to="{ name: 'casino-provider-provider', params: { provider: provider.slug } }">
              <MipixCard class="hover:!bg-secondary-950/50">
                <div class="provider-tile py-2.5 !flex flex-col text-center items-center justify-evenly">
                  <img :src="provider.image" :alt="provider.alias" class="provider-logo" loading="lazy" />
                </div>
              </MipixCard>
            </nuxt-link>
          </swiper-slide>
        </swiper>
      </div>

      <div class="w-full" v-if="appStore.gameList.length">
        <div class="head-one-category">
          <div class="section-title">
            <span>Todos os jogos</span>
          </div>
          <nuxt-link :to="{ name: 'casino-all' }" class="category-button">
            <span>+5k</span> <span>></span>
          </nuxt-link>
        </div>
        <swiper class="feature-cards" :slidesPerView="laptop ? 7.3 : 2.8" :space-between="12">
          <swiper-slide v-for="(game, key) in appStore.gameList" v-bind:key="key" :class="{ 'feature-card': !mounted }">
            <GameInfoItem :provider="game.merchant" :game="game" />
          </swiper-slide>
        </swiper>
      </div>

      <div class="w-full" v-if="questionList.length">
        <div class="head-one-category">
          <div class="section-title">
            <span>Perguntas Frequentes Sobre o Cassino MiPix</span>
          </div>
        </div>
        <div class="w-full mt-4">
          <div id="accordion-collapse" data-accordion="collapse" data-inactive-classes="accordion-closed"
            data-active-classes="accordion-opened" class="index-features flex-col gap-2 mt-4">
            <div class="w-full" v-for="(question, key) in questionList" :key="question.id">
              <div class="collapsed-questions" :class="{ 'accordion-opened': key === 0, 'accordion-closed': key !== 0 }"
                :data-accordion-target="`#accordion-collapse-body-${question.id}`" :aria-expanded="key === 0">
                <span>{{ question.title }}</span>
                <Icon name="gravity-ui:chevron-up" data-accordion-icon :class="{ 'rotate-180': key !== 0 }" />

              </div>
              <div :id="`accordion-collapse-body-${question.id}`" class="collapsed-questions-content"
                :class="{ 'hidden': key !== 0 }">
                <p>
                  {{ question.content }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-full">
        <div class="head-one-category">
          <div class="section-title">
            <span>Bônus de Cassino e Cashback</span>
          </div>
        </div>
        <div class="mt-2">
          Na MiPix, acreditamos que os BÔNUS de Cassino são imprecidíveis. Para nós
          é importante recompensar os nossos jogadores pela a sua lealdade e pelo
          tempo que passam no nosso cassino. Aqui estão três tipos de bônus para
          jogos de Cassino com dinheiro real disponíveis na MiPix:
        </div>
        <div class="mt-2">
          <div class="section-title !text-sm before:w-3 before:h-3 before:bg-contain" v-for="n in news">
            <div class="w-full lg:grid-cols-none lg:flex">
              <span class="after:!content-['']">{{ n.title }}:</span>
              <div class="font-normal italic">{{ n.content }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CassinoSearchModal />
  </div>
</template>

<style scoped>
.feature-cards .feature-card {
  @apply w-28 sm:w-44;
}

.lobby-game {
  @apply grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-6 gap-4;
}
</style>