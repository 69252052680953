<script setup lang="ts">
import { emitter } from '~/events'
import { Modal } from 'flowbite'
import type { ModalOptions } from 'flowbite'
import SearchContent from './SearchContent.vue'
import type Game from '~/interfaces/game'

const appStore = useAppStore()

const limit: number = 60
const gameSearch = ref<string | null>(null)
const games = ref<Game[]>(appStore.gameList.slice(0, limit))
const inputRef = ref<HTMLInputElement | null>(null)

onMounted(() => {
  const targetEl: HTMLElement | null = document.getElementById('search-modal')

  const options: ModalOptions = {
    closable: true,
    backdropClasses: 'bg-gray-900/20 backdrop-blur fixed inset-0 z-[99]',
    onShow: () => inputRef.value?.focus(),
    onHide: () => {
      gameSearch.value = null

      setTimeout(() => {
        const backdrop = document.querySelector('div[modal-backdrop]')
        
        if(backdrop)
          backdrop?.remove()
      }, 300)
    }
  }

  const searchModal = new Modal(targetEl, options)

  emitter.on('search-modal', async (event) => {
    if (event == 'open') searchModal.show()
    else if (event == 'close') searchModal.hide()
  })
})

const filter = (query: string) => {
  const search = new RegExp(query, 'i') // prepare a regex object
  let games = appStore.gameList.filter((item) => search.test(item.title))
  return games
}

watch(appStore.gameList, (val) => {
  games.value = val.slice(0, limit)
})

watch(gameSearch, (query) => {
  if(query){
    const filtered = filter(query);
    games.value = filtered.slice(0, limit)
  } else {
    games.value = appStore.gameList.slice(0, limit)
  }
})

const closeModal = () => emitter.emit('search-modal', 'close')
</script>
<template>
  <!-- Main modal -->
  <div
    id="search-modal"
    tabindex="-1"
    aria-hidden="true"
    class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[999] justify-center items-center w-full md:inset-0 md:h-[calc(100%-1rem)] max-h-full"
  >
    <div class="relative w-full md:max-w-3xl max-h-full">
      <!-- Modal content -->
      <div
        class="fixed lg:relative top-0 right-0 bottom-0 left-0 xl:relative md:rounded-2xl shadow bg-secondary-940 w-full h-full xl:max-h-[48rem] xl:w-[43.5rem] overflow-hidden"
      >
        <!-- Modal body -->
        <div class="flex flex-col">
          <div
            class="w-full flex flex-row-reverse lg:flex-row h-full justify-between items-center gap-4 p-4"
          >
            <div class="w-full">
              <input
                ref="inputRef"
                type="text"
                v-model="gameSearch"
                class="w-full flex relative items-center border border-solid border-primary-600 h-10 rounded-3xl bg-transparent text-xs outline-none text-white leading-5 hover:border-primary-300 focus:border-primary-600 focus:ring-transparent"
                placeholder="Procurar jogos &amp; dealers"
              />
            </div>
            <div
              data-modal-hide="search-modal"
              @click.capture="closeModal"
              class="cursor-pointer uppercase font-bold text-xs"
            >
              <span class="hidden sm:block">Fechar</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="block h-[unset] max-w-[unset] sm:hidden"
                aria-hidden="true"
              >
                <path
                  data-v-0c36a938=""
                  d="M14.7791 20C14.3599 20 13.9423 19.8368 13.6287 19.512L7.44805 13.1122C6.84327 12.4834 6.85127 11.4866 7.46885 10.869L13.8687 4.46919C14.4927 3.8436 15.5055 3.8436 16.131 4.46919C16.755 5.09477 16.755 6.10595 16.131 6.73153L10.8432 12.0194L15.9294 17.2881C16.5438 17.9249 16.5262 18.9376 15.891 19.5504C15.579 19.8512 15.1791 20 14.7791 20"
                ></path>
              </svg>
            </div>
          </div>
          <div class="h-screen overflow-y-auto max-h-[82vh] w-full">
            <SearchContent :games="games" :search="gameSearch" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>